import React, { useMemo, useCallback, useRef, useState, useEffect, memo, ReactElement } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import Icon from '@chayns-components/Icon';
import { useDispatch, useSelector } from 'react-redux';

import { INTERCOM } from '../../../../../../constants/types';

import MailItemDate from '../item-date/ItemDate';
import UserImage from '../user-image/UserImage';
import MessageStatus from './message-status/MessageStatus';
import PreviewText from './preview-text/PreviewText';
import { getIsExactOneLocationGiven, getActiveMembers } from './utils/member';
import { getThreadTitle } from './utils/thread';
import { useThreadImage } from './utils/useThreadImage';
import getIsThreadRead from './utils/getIsThreadRead';
import { getAppState } from '../../../../../../redux-modules/app/selector';
import { getUserState } from '../../../../../../redux-modules/user/selector';
import { setContextMenu } from '../../../../../../redux-modules/context-menu/actions';
import { setSelectedItem } from '../../../../../../redux-modules/app/actions';
import appCall from "../../../../../../utils/appCall";

const IntercomItem = ({
    thread,
    isInSpringBoard
}) => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        selectedItem,
        colorMode
    } = app;

    const user = useSelector(getUserState);

    const { id, lastMessage, members, name, creationTime, lastMessageTime } = thread;
    const { imageUrl, isRound } = useThreadImage(thread, user.userId);

    const isThreadRead = useMemo(() => getIsThreadRead(thread, user), [thread, user]);

    const contextMenuRef = useRef(null);
    const isMenuOpenRef = useRef(false);

    const [isHovered, setIsHovered] = useState(false);
    const hoverTimeout = useRef(null);

    const isSelected = id === selectedItem?.id && selectedItem?.type === INTERCOM;

    const handleSelect = useCallback(() => {
        if(isInSpringBoard) {
            chayns.selectTapp({
                id: 251441,
                params: 'tid=' + id,
            });

            return;
        }
        if (isSelected) return;
        appCall(292, {
            show: false,
        }, {
            awaitResult: false,
        });
        dispatch(setSelectedItem({
            ...thread,
            type: INTERCOM,
        }));
    }, [isSelected, dispatch, thread]);

    const title = getThreadTitle(thread, user.userId);

    const activeMembers = getActiveMembers(members);

    const isExactOneLocationGiven = getIsExactOneLocationGiven(activeMembers);

    const subtitle = useMemo(() => {
        let text: string | ReactElement = '';

        if (isExactOneLocationGiven && typeof name === 'string' && name.trim() !== '') {
            text = name;
        } else if (lastMessage) {
            text = (
                <>
                    <MessageStatus
                        message={lastMessage}
                        members={members}
                        shouldAlignLeft
                        userId={user.userId}
                    />
                    <PreviewText
                        message={lastMessage}
                        members={members}
                        userId={user.userId}
                    />
                </>
            );
        }

        return text;
    }, [isExactOneLocationGiven, lastMessage, members, name, user.userId]);

    useEffect(() => {
        const listener = () => {
            if (contextMenuRef.current) {
                contextMenuRef.current.hide();
            }
            isMenuOpenRef.current = false;
            setIsHovered(false);
        };
        if (isHovered) {
            window.removeEventListener('blur', listener);
            window.addEventListener('blur', listener);
        } else {
            window.removeEventListener('blur', listener);
        }
        return () => window.removeEventListener('blur', listener);
    }, [isHovered]);

    const handleMouseEnter = useCallback(() => {
        hoverTimeout.current = setTimeout(() => {
            if (!isHovered) {
                setIsHovered(true);
            }
        }, 50);
    }, [isHovered]);

    const handleMouseLeave = useCallback(() => {
        clearTimeout(hoverTimeout.current);
        if (isHovered) {
            setIsHovered(false);
        }
    }, [isHovered]);

    return (
        <>
            <div
                className={classNames('mail-item', {
                    selected: isSelected,
                    'is-darkmode': colorMode === 'dark',
                    'is-read': isThreadRead,
                })}
                onClick={handleSelect}
                onContextMenu={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch(setContextMenu({
                        x: e.clientX,
                        y: e.clientY + window.scrollY,
                        id,
                        isList: true,
                        contextType: INTERCOM,
                    }));
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div
                    className="mail-item-header"
                >
                    <div
                        className="mail-item-header-left"
                    >
                        <UserImage
                            url={imageUrl}
                            isSquare={!isRound}
                            type={INTERCOM}
                        />
                        <div
                            className="mail-item-info"
                        >
                            <div
                                className="mail-item-info-title"
                            >
                                {title}
                            </div>
                            <div
                                className="mail-item-bottom"
                            >
                                <div
                                    className="mail-item-info-subtitle"
                                >
                                    {subtitle}
                                </div>
                                <div
                                    className="mail-item-header-right"
                                >
                                    <MailItemDate
                                        date={lastMessageTime || creationTime}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <CSSTransition
                        timeout={100}
                        classNames="menu-grow"
                        unmountOnExit
                        appear
                        mountOnEnter
                        in={isHovered}
                    >
                        <div
                            className="icon-wrapper menu"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                dispatch(setContextMenu({
                                    x: e.clientX,
                                    y: e.clientY + window.scrollY,
                                    id,
                                    isList: true,
                                    contextType: INTERCOM,
                                    Type: INTERCOM,
                                }));
                            }}
                        >
                            <Icon icon="far fa-ellipsis-v"/>
                        </div>
                    </CSSTransition>
                </div>
            </div>
            <style jsx>
                {`
                    .mail-item {
                        height: 64px;
                        overflow: hidden;
                        cursor: pointer;
                        width: 100%;
                        user-select: none;

                      &.is-darkmode {
                        color: rgb(212, 212, 212);

                        .is-cc {
                          color: #a7b4ff;
                        }
                      }


                      &:before {
                            content: "";
                            z-index: 0;
                            width: 0;
                            height: 100%;
                            position: absolute;
                            background-color: var(--mouseOverColor);
                            opacity: 0;
                            transform: scaleX(1) translateX(0);
                            will-change: transform, opacity;
                        }

                        &.selected {
                            &:before {
                                opacity: 1;
                                background-color: var(--mouseOverColor);
                                transform: scaleX(200) translateX(30%);
                                width: 100%;
                            }
                        }

                        &-header {
                            padding: 12px 15px 12px 9px;
                            display: flex;
                            justify-content: space-between;

                            &-left {
                                display: flex;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                z-index: 1;
                                width: 100%;

                                .mail-item-info {
                                    flex: 1 1;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    min-width: 1px;

                                    &-title {
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        line-height: 1.3rem;
                                        font-weight: 700;
                                    }

                                    &-subtitle {
                                        font-size: 85%;
                                        opacity: .6;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        margin-right: 3px;
                                    }
                                }
                            }

                            &-right {
                                z-index: 1;
                                display: flex;
                                align-items: center;
                            }
                        }

                        &.is-read {
                            .mail-item-header-left {
                                opacity: .4;
                            }
                        }

                        .mail-item-bottom {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }

                        .menu {
                            transition: width 100ms ease-out, opacity 100ms ease-out;
                            z-index: 1;

                            &.icon-wrapper {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 25px;
                                height: 25px;

                                i {
                                    font-size: 20px;
                                }
                            }

                            &.menu-grow {
                                &-enter {
                                    width: 0;
                                    opacity: 0;
                                }

                                &-enter-active {
                                    width: 25px;
                                    opacity: 1;
                                }

                                &-exit {
                                    width: 25px;
                                    opacity: 1;
                                }

                                &-exit-active {
                                    width: 0;
                                    opacity: 0;
                                }
                            }
                        }
                    }
                `}
            </style>
        </>
    );
};

IntercomItem.propTypes = {
    thread: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        creationTime: PropTypes.string.isRequired,
        lastMessageTime: PropTypes.string.isRequired,
        lastMessage: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
        members: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
        })).isRequired,
    }).isRequired,
};

IntercomItem.displayName = 'IntercomItem';

export default memo(IntercomItem);
