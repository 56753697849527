import { isSameDay } from 'date-fns';
import isSameYear from 'date-fns/isSameYear';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import de from 'date-fns/locale/de';
import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import logger from '../constants/logger';
import { getTextStringState } from '../redux-modules/text-strings/selector';
import { INTERCOM, MAIL } from '../constants/types';
import mergeLists, { Item } from './mergeLists';
import { Thread } from '../types/thread';
import { Mail } from '../types/smartClient';

export const useMessageList = (mails: Array<Mail>, threads: Array<Thread>, showLoadingAnimation: boolean, isInSpringboard: boolean, infocenter: boolean): { itemCount: number, list: Array<Item> } => {
    const textStrings = useSelector(getTextStringState);

    const getDateText = useCallback((date, id) => {
        try {
            const today = (() => {
                const d = new Date();
                d.setHours(0);
                d.setMinutes(0);
                return d;
            })();
            const yesterday = new Date(today.getTime() - 1000 * 60 * 60 * 24);
            const isCurrentYear = isSameYear(date, today);
            const isLast7Days = differenceInDays(yesterday, date) < 6;
            if (isSameDay(date, yesterday)) {
                return textStrings.txt_david_rest_api_mail_list_yesterday || 'Gestern';
            }
            if (isLast7Days) {
                return textStrings.txt_david_rest_api_mail_list_last_seven_days || 'Letzte 7 Tage';
            }
            return `${format(date, 'MMMM', { locale: de })} ${isCurrentYear ? '' : date.getFullYear()}`;
        } catch (e) {
            logger().error({
                message: 'error creating placeholder',
                section: 'utils.js',
                personId: chayns.env.user.personId,
                data: {
                    date,
                    id,
                    error: e,
                },
            });
            return '';
        }
    }, [textStrings]);

    const mergedList = useMemo(() => {
        if (showLoadingAnimation) {
            return [];
        }
        return mergeLists(mails, threads || []).slice(0, isInSpringboard ? 5 : Infinity);
    }, [isInSpringboard, mails, showLoadingAnimation, threads]);

    if (!infocenter) {
        return useMemo(() => ({
            list: mergedList,
            itemCount: mergedList.length,
        }), [mergedList]);
    }

    return useMemo(() => {
        if (mergedList.length === 0) {
            return {
                list: [],
                itemCount: 0,
            };
        }

        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);

        const newList: Array<Item> = [{
            id: 'today',
            isPlaceholder: true,
            children: [],
        }];

        mergedList.forEach((item) => {
            // Handle mail
            if (item.type === MAIL) {
                if (item.createdDateTime && item.createdDateTime !== 'undefined') {
                    const date = new Date(item.createdDateTime);
                    if (isSameDay(date, today)) {
                        newList.find((timespan) => timespan.id === 'today')?.children.push(item);
                    } else {
                        const dateText = getDateText(date, item.id);
                        const targetTimespan = newList.find((timespan) => timespan.id === dateText);
                        if (targetTimespan) {
                            targetTimespan.children.push(item);
                        } else {
                            newList.push({
                                id: dateText,
                                isPlaceholder: true,
                                children: [item],
                            });
                        }
                    }
                }
            }
            if (item.type === INTERCOM) {
                if (item.lastMessageTime && item.lastMessageTime !== 'undefined') {
                    const date = new Date(item.lastMessageTime);
                    if (isSameDay(date, today)) {
                        newList.find((timespan) => timespan.id === 'today')?.children.push(item);
                    } else {
                        const dateText = getDateText(date, item.id);
                        const targetTimespan = newList.find((timespan) => timespan.id === dateText);
                        if (targetTimespan) {
                            targetTimespan.children.push(item);
                        } else {
                            newList.push({
                                id: dateText,
                                isPlaceholder: true,
                                children: [item],
                            });
                        }
                    }
                }
            }
        });
        const sortedList = newList.flatMap((timespan) => {
            if (timespan.children.length > 0) {
                return [{
                    ...timespan,
                    children: null,
                },
                ...timespan.children,
                ];
            }
            return [...timespan.children];
        });

        const firstPlaceholderIndex = sortedList.findIndex((item) => item.isPlaceholder);
        if (firstPlaceholderIndex > -1) {
            sortedList.splice(firstPlaceholderIndex, 1);
        }
        const placeholderCount = sortedList.filter((item) => item.isPlaceholder).length;

        return {
            list: sortedList,
            itemCount: mergedList.length + placeholderCount + 1,
        };
    }, [getDateText, mergedList]);
};
