import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Item } from '../../../../../../utils/mergeLists';
import { getAppState } from '../../../../../../redux-modules/app/selector';

type StickyHeaderProps = {
    scrollValue: number;
    list: Array<Item>;
};

const StickyHeader: FC<StickyHeaderProps> = ({ scrollValue, list }) => {
    const { colorMode } = useSelector(getAppState);

    const index = Math.floor((scrollValue + 35) / 64);
    const firstPlaceholderIndex = useMemo(() => list.findIndex((item) => item.isPlaceholder), [list]);
    const currentPlaceholder = useMemo(() => [...list].reverse().find((item, i) => item.isPlaceholder && list.length - i <= index), [index, list]);

    return (
        <>
            <div
                className={clsx('sticky-header', colorMode !== 'dark' && 'chayns__color--205')}
                style={{
                    top: document.querySelector('.info-center-header')?.getBoundingClientRect()?.height || 10,
                }}
            >
                {currentPlaceholder?.id}
            </div>
            <style jsx>
                {`
                    .sticky-header {
                        position: absolute;
                        width: 100%;
                        height: 37px;
                        padding: 2px 22px 0;
                        display: ${firstPlaceholderIndex > -1 && index > firstPlaceholderIndex ? 'block' : 'none'};
                        transition: box-shadow 0.1s;
                        box-shadow: ${scrollValue > 0 || (firstPlaceholderIndex > -1 && index > firstPlaceholderIndex) ? '0 6px 8px -6px rgba(0, 0, 0, .15)' : 'inset 0 0 0 0 rgba(0, 0, 0, 0)'};
                        background-color: ${colorMode === 'dark' ? '#121212' : '#f2f2f7'};
                        ${colorMode === 'dark' ? 'color: #d4d4d4;' : ''}
                    }
                `}
            </style>
        </>
    );
};

export default StickyHeader;
