import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Member, Thread } from '../../types/thread';
import { loadIntercomClient } from '../../api/intercom/get/loadIntercomClient';
import { RootState } from '../rootReducer';
import { SET_DELETED_INTERCOM_THREADS, SET_INTERCOM_LOADING_ERROR, SET_INTERCOM_PLUGINS, SET_INTERCOM_THREADS, SET_READ_INTERCOM_THREAD, UPDATE_INTERCOM_THREAD } from './types';
import { SelectedItem } from '../../types/selectedItem';
import { getMyMember } from '../../components/tapp/tapp-content/sites-tapp/messages/intercom-item/utils/member';
import getIsThreadRead from '../../components/tapp/tapp-content/sites-tapp/messages/intercom-item/utils/getIsThreadRead';
import { patchThread } from '../../api/intercom/patch/patchThread';
import { deleteThread } from '../../api/intercom/delete/deleteThread';
import { patchLeaveGroup } from '../../api/intercom/patch/patchLeaveGroup';
import { loadIntercomById } from '../../api/intercom/get/loadIntercomById';
import { IntercomPlugin } from '../../api/intercom/get/loadIntercomPlugins';

export type Intercom = {
   fetchedData: boolean,
   lastFetch: string,
   threads: Array<Thread>,
   plugins: Array<IntercomPlugin>
};

export const setIntercomLoadingError = createAction<boolean>(SET_INTERCOM_LOADING_ERROR);

export const handleLoadIntercom = createAsyncThunk(
    'intercom/handleLoadIntercom',
    async ({ lastFetch, take }: { lastFetch?: string }, thunkApi) => {
        const { status, result } = await loadIntercomClient(lastFetch, take);

        if (status === 200 || status === 204) {
            const state = thunkApi.getState() as RootState;
            const { selectedItem } = state.app;

            return {
                result: result?.threads || [],
                updateLastFetch: true,
                selectedItem,
                overrideThreads: !lastFetch,
            };
        }
        thunkApi.dispatch(setIntercomLoadingError(true));
        throw new Error('Failed to load threads');
    },
);

export const setIntercomThreads = createAction<{ result: Array<Thread>, selectedItem: SelectedItem, updateLastFetch: boolean }>(SET_INTERCOM_THREADS);

export const setReadIntercomThread = createAction<{ id:string, selectedItem: SelectedItem }>(SET_READ_INTERCOM_THREAD);

export const setUpdatedIntercomThread = createAction<{ thread: Thread, selectedItem: SelectedItem }>(UPDATE_INTERCOM_THREAD);

export const setIntercomPlugins = createAction<Array<IntercomPlugin>>(SET_INTERCOM_PLUGINS);

export const handleUpdateRead = createAsyncThunk(
    'intercom/handleUpdateRead',
    async ({ thread }: { thread: Thread }, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { selectedItem } = state.app;

        const { members } = thread;
        const userId = typeof chaynsInfo !== 'undefined' ? window.chaynsInfo.User.ID : chayns.env.user.id;
        const myMember = getMyMember(members, userId);

        const isThreadRead = getIsThreadRead(thread);

        const { status } = await patchThread(thread.id, myMember.id, 'read', { read: !isThreadRead });

        if (status === 200) {
            if (!isThreadRead) {
                thunkApi.dispatch(setReadIntercomThread({
                    id: thread.id,
                    selectedItem,
                }));
            } else {
                const updatedThread = {
                    ...thread,
                    members: members.map((member) => {
                        if (member.tobitId === userId) {
                            return {
                                ...member,
                                unreadFlag: isThreadRead,
                            };
                        }
                        return member;
                    }),
                };
                thunkApi.dispatch(setUpdatedIntercomThread({
                    thread: updatedThread,
                    selectedItem,
                }));
            }
        }
        return null;
    },
);

export const setDeletedIntercomThreads = createAction<{ threadIds : Array<string> }>(SET_DELETED_INTERCOM_THREADS);

export const handleUpdateMute = createAsyncThunk(
    'intercom/handleUpdateMute',
    async ({ thread }: { thread: Thread }, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { selectedItem } = state.app;

        const { members } = thread;
        const userId = typeof chaynsInfo !== 'undefined' ? window.chaynsInfo.User.ID : chayns.env.user.id;
        const myMember = getMyMember(members, userId);

        const {
            isThreadMuted,
        } = myMember;
        const { status } = await patchThread(thread.id, myMember.id, 'mute', { mute: !isThreadMuted });

        if (status === 200) {
            const updatedThread = {
                ...thread,
                members: members.map((member: Member) => {
                    if (member.tobitId === userId) {
                        return {
                            ...member,
                            isThreadMuted: !isThreadMuted,
                        };
                    }
                    return member;
                }),
            };
            thunkApi.dispatch(setUpdatedIntercomThread({
                thread: updatedThread,
                selectedItem,
            }));
        }
    },
);

export const handleDeleteChat = createAsyncThunk(
    'intercom/handleDeleteChat',
    async ({ thread }: { thread: Thread }, thunkApi) => {
        const { members } = thread;
        const userId = typeof chaynsInfo !== 'undefined' ? window.chaynsInfo.User.ID : chayns.env.user.id;
        const myMember = getMyMember(members, userId);

        const { status } = await deleteThread(thread.id, myMember.id);

        if (status === 200) {
            thunkApi.dispatch(setDeletedIntercomThreads({
                threadIds: [thread.id],
            }));
        }
        return null;
    },
);

export const handleLeaveGroup = createAsyncThunk(
    'intercom/handleLeaveGroup',
    async ({ thread }: { thread: Thread }, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { selectedItem } = state.app;

        const { members } = thread;
        const userId = typeof chaynsInfo !== 'undefined' ? window.chaynsInfo.User.ID : chayns.env.user.id;
        const myMember = getMyMember(members, userId);

        const { status } = await patchLeaveGroup(thread.id, myMember.id);

        if (status === 200) {
            const updatedThread = {
                ...thread,
                members: members.map((member: Member) => {
                    if (member.tobitId === userId) {
                        return {
                            ...member,
                            leaveTime: new Date(),
                        };
                    }
                    return member;
                }),
            };
            thunkApi.dispatch(setUpdatedIntercomThread({
                thread: updatedThread,
                selectedItem,
            }));
        }
        return null;
    },
);

export const handleLoadThreadById = createAsyncThunk(
    'intercom/handleLoadThreadById',
    async ({ id }: { id: string }, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { selectedItem } = state.app;

        const { status, result } = await loadIntercomById(id);
        if (status === 200 && result) {
            thunkApi.dispatch(setIntercomThreads({
                result: [result.thread],
                selectedItem,
                updateLastFetch: false,
            }));
        }
        return null;
    },
);
