/* eslint-disable react/require-default-props */
import React, { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { Icon, SmallWaitCursor } from '@chayns-components/core';
import { createPortal } from 'react-dom';

type RefreshScrollProps = {
    onRefresh: () => Promise<void>;
    isDisabled?: boolean;
    container?: HTMLElement;
}

const RefreshScroll: FC<PropsWithChildren<RefreshScrollProps>> = ({ onRefresh, children, isDisabled = false, container }) => {
    const [top, setTop] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const isLoadingRef = useRef(isLoading);

    const touchStartPosition = useRef<{ x: number; y: number; }>({
        x: 0,
        y: 0,
    });
    const scrollContainer = useRef<HTMLDivElement>();

    const handleStart = useCallback((e) => {
        if (typeof e.targetTouches !== 'undefined') {
            const touch = e.targetTouches[0];
            touchStartPosition.current.x = touch.screenX;
            touchStartPosition.current.y = touch.screenY;
        } else {
            touchStartPosition.current.x = e.screenX;
            touchStartPosition.current.y = e.screenY;
        }
    }, []);

    const handleEnd = useCallback(() => {
        if (scrollContainer.current.scrollTop === 0 && !isLoading) {
            isLoadingRef.current = false;
            touchStartPosition.current = {
                x: 0,
                y: 0,
            };
            // abort pull to refresh
            scrollContainer.current.style.pointerEvents = '';
            setTop(0);
        }
    }, [isLoading]);

    const handleMove = useCallback((e) => {
        const currentTouch = (() => {
            if (typeof e.changedTouches !== 'undefined') {
                const touch = e.changedTouches[0];
                return {
                    x: touch.screenX,
                    y: touch.screenY,
                };
            }
            return {
                x: e.screenX,
                y: e.screenY,
            };
        })();

        const yChange = currentTouch.y - touchStartPosition.current.y;
        const xChange = Math.abs(currentTouch.x - touchStartPosition.current.x);
        if (yChange > xChange && yChange > 5 && (xChange < 20 || top > 0)) {
            // reload
            if (yChange > 225 && !isLoadingRef.current) {
                setIsLoading(true);
                isLoadingRef.current = true;
                onRefresh().then(() => {
                    setIsLoading(false);
                    setTop(0);
                    scrollContainer.current.style.pointerEvents = '';
                    touchStartPosition.current = {
                        x: 0,
                        y: 0,
                    };
                    handleEnd();
                });
                return;
            }
            setTop(Math.min(225, yChange) - Math.min(225, yChange) / 3);
            scrollContainer.current.style.pointerEvents = 'none';
        }
    }, [handleEnd, onRefresh, top]);

    useEffect(() => {
        if (isDisabled) {
            return undefined;
        }
        const currentScrollContainer = scrollContainer.current;
        currentScrollContainer.addEventListener('touchstart', handleStart);
        currentScrollContainer.addEventListener('touchmove', handleMove, {
            passive: true,
        });
        currentScrollContainer.addEventListener('touchend', handleEnd);
        return () => {
            currentScrollContainer.removeEventListener('touchstart', handleStart);
            currentScrollContainer.removeEventListener('touchmove', handleMove);
            currentScrollContainer.removeEventListener('touchend', handleEnd);
            currentScrollContainer.style.pointerEvents = '';
        };
    }, [handleStart, handleMove, handleEnd, isDisabled]);

    return (
        <>
            <div ref={scrollContainer} className="refresh-scroll">
                {!isDisabled && createPortal(
                    <div
                        className="refresh-scroll-icon"
                        style={{
                            transform: `translate3d(-50%, ${(isLoading ? 150 : top) - 84}px, 0px) rotate(${(isLoading ? 150 : top) * 1.3}deg)`,
                        }}
                    >
                        {isLoading ? (
                            <div className="icon_wrapper">
                                <SmallWaitCursor/>
                            </div>
                        ) : (
                            <div
                                className="icon_wrapper"
                                style={{
                                    opacity: top / 150,
                                }}
                            >
                                <Icon icons={['far fa-redo']} className="refresh_icon" color="black" size={18}/>
                            </div>
                        )}
                    </div>,
                    container || document.body,
                )}

                {children}
            </div>
            <style jsx global>
                {`
                    .refresh-scroll {
                        height: 100%;
                    }

                    .refresh-scroll-icon {
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translate3d(-50%, 0, 0);
                        width: 30px;
                        height: 30px;
                        justify-content: center;
                        align-items: center;
                        background: #fff;
                        border-radius: 50%;
                        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.2);
                        z-index: 50;

                        .icon_wrapper {
                            width: 30px;
                            height: 30px;
                            justify-content: center;
                            display: flex;
                            align-items: center;

                            .refresh_icon {
                                transform-origin: center center;
                            }
                        }
                    }
                `}
            </style>
        </>
    );
};

export default RefreshScroll;
