import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { useDispatch, useSelector } from 'react-redux';
import Icon from '@chayns-components/Icon';
import { openSmartClient } from '../../../../../../utils/smart-client/openSmartClient';
import { FAX, MAIL } from '../../../../../../constants/types';
import MailItemDate from '../item-date/ItemDate';
import UserImage from '../user-image/UserImage';
import { MOBILE_VIEW_BREAKPOINT } from '../../../../../../constants/size';
import { getAppState } from '../../../../../../redux-modules/app/selector';
import { getEnvState } from '../../../../../../redux-modules/env/selector';
import { setContextMenu } from '../../../../../../redux-modules/context-menu/actions';
import { setSelectedItem } from '../../../../../../redux-modules/app/actions';
import { Mail } from '../../../../../../types/smartClient';
import appCall from '../../../../../../utils/appCall';

type MailItemTypes = {
    item: Mail,
    endpoint: string,
    tokenJti: string,
};

const MailItem: React.FC<MailItemTypes> = ({
    item,
    endpoint,
    tokenJti,
    isInSpringBoard,
    infocenter,
}) => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        activeDavidSiteId,
        colorMode,
        width,
        selectedItem,
    } = app;

    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
        isMobile,
        isTablet,
    } = env;

    const {
        id,
        from,
        subject,
        createdDateTime,
        isCc,
        isRead,
        importance,
        previewImageUrl,
        type,
        hasAttachments,
        properties,
    } = item;

    const isSelected = id === selectedItem?.id && selectedItem?.type === MAIL;

    const contextMenuRef = useRef(null);
    const isMenuOpenRef = useRef(false);

    const [isHovered, setIsHovered] = useState(false);
    const hoverTimeout = useRef(null);

    const icon = useMemo(() => {
        let iconName: string;
        if (type?.toLowerCase() === FAX) {
            iconName = 'fa-fax';
        } else if (hasAttachments && type?.toLowerCase() !== FAX) {
            iconName = 'fa-paperclip';
        } else if (properties?.isReminder) {
            iconName = 'fa-bell';
        } else if (properties?.isSigned) {
            iconName = 'fa-award';
        } else {
            return null;
        }

        return (
            <div className="chayns__color--headline mail-item-header-right-icon">
                <Icon icon={`far ${iconName}`}/>
            </div>
        );
    }, [colorMode, hasAttachments, properties?.isReminder, properties?.isSigned, type]);

    const handleSelect = useCallback(() => {
        if (isInSpringBoard) {
            chayns.selectTapp({
                id: 662163,
                params: `id=${id}`,
            });

            return;
        }

        if (isSelected) {
            return;
        }
        if (width >= MOBILE_VIEW_BREAKPOINT || infocenter) {
            appCall(292, {
                show: false,
            }, {
                awaitResult: false,
            });
            dispatch(setSelectedItem({
                ...item,
                type: MAIL,
            }));
        } else {
            openSmartClient(id, null, activeDavidSiteId, isMyChaynsApp);
        }
    }, [activeDavidSiteId, id, isMyChaynsApp, isSelected, item, dispatch, width, isInSpringBoard]);

    useEffect(() => {
        const listener = () => {
            if (contextMenuRef.current) {
                contextMenuRef.current.hide();
            }
            isMenuOpenRef.current = false;
            setIsHovered(false);
        };
        if (isHovered) {
            window.removeEventListener('blur', listener);
            window.addEventListener('blur', listener);
        } else {
            window.removeEventListener('blur', listener);
        }
        return () => window.removeEventListener('blur', listener);
    }, [isHovered]);

    const handleMouseEnter = useCallback(() => {
        hoverTimeout.current = setTimeout(() => {
            if (!isHovered) {
                setIsHovered(true);
            }
        }, 50);
    }, [isHovered]);

    const handleMouseLeave = useCallback(() => {
        clearTimeout(hoverTimeout.current);
        if (isHovered) {
            setIsHovered(false);
        }
    }, [isHovered]);

    const highPrio = importance?.toLowerCase() === 'high';
    const lowPrio = importance?.toLowerCase() === 'low';

    return useMemo(() => (
        <>
            <div
                className={classNames('mail-item', {
                    selected: isSelected,
                    'is-darkmode': colorMode === 'dark',
                    'mobile-view': width < MOBILE_VIEW_BREAKPOINT,
                    'is-read': isRead,
                })}
                onClick={handleSelect}
                onContextMenu={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch(setContextMenu({
                        x: e.clientX,
                        y: e.clientY + window.scrollY,
                        id,
                        isList: true,
                        contextType: MAIL,
                    }));
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div
                    className="mail-item-header"
                >
                    <div
                        className="mail-item-header-left"
                    >
                        <UserImage
                            endpoint={endpoint}
                            tokenJti={tokenJti}
                            fromId={from?.id}
                            type={MAIL}
                            url={previewImageUrl}
                        />
                        <div
                            className="mail-item-info"
                        >
                            <div
                                className={classNames('mail-item-info-title', {
                                    'is-cc': isCc,
                                    'chayns__color--red-3': highPrio && colorMode === 'dark',
                                    'chayns__color--red-2': highPrio && colorMode !== 'dark',
                                    'chayns__color--006': lowPrio,
                                })}
                            >
                                {properties?.isAnswered && (
                                    <span className="mail-item-info-title-icon">
                                        <Icon icon="far fa-reply" size={12}/>
                                    </span>
                                )}
                                {properties?.isRedirected && (
                                    <span className="mail-item-info-title-icon">
                                        <Icon icon="far fa-share" size={12}/>
                                    </span>
                                )}
                                {subject || 'Ohne Betreff'}
                            </div>
                            <div
                                className="mail-item-bottom"
                            >
                                <div
                                    className="mail-item-info-subtitle"
                                >
                                    {from?.name || from?.email}
                                </div>
                                <div
                                    className="mail-item-header-right"
                                >
                                    {icon}
                                    <MailItemDate
                                        date={createdDateTime}
                                    />
                                </div>
                            </div>
                        </div>
                        {!isMobile && !isTablet && (
                            <CSSTransition
                                timeout={100}
                                classNames="menu-grow"
                                unmountOnExit
                                appear
                                mountOnEnter
                                in={isHovered}
                            >
                                <div
                                    className="icon-wrapper menu"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        dispatch(setContextMenu({
                                            x: e.clientX,
                                            y: e.clientY + window.scrollY,
                                            id,
                                            isList: true,
                                            contextType: MAIL,
                                        }));
                                    }}
                                >
                                    <Icon icon="far fa-ellipsis-v"/>
                                </div>
                            </CSSTransition>
                        )}
                    </div>
                </div>
            </div>
            <style jsx global>
                {`
                  .mail-item {
                    height: 64px;
                    overflow: hidden;
                    cursor: pointer;
                    width: 100%;
                    user-select: none;

                    

                    &:before {
                      content: "";
                      z-index: 0;
                      width: 0;
                      height: 100%;
                      position: absolute;
                      background-color: var(--mouseOverColor);
                      opacity: 0;
                      transform: scaleX(1) translateX(0);
                      will-change: transform, opacity;
                    }

                    &.selected {
                      &:before {
                        opacity: 1;
                        background-color: var(--mouseOverColor);
                        transform: scaleX(200) translateX(30%);
                        width: 100%;
                      }
                    }

                    &-header {
                      padding: 12px 15px 12px 9px;
                      display: flex;
                      justify-content: space-between;

                      &-left {
                        display: flex;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        z-index: 1;
                        width: 100%;

                        .mail-item-info {
                          flex: 1 1;
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          min-width: 1px;

                          &-title {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            line-height: 1.3rem;
                            font-weight: 700;

                            &.is-cc {
                              color: #384ebc;
                            }
                              
                              &-icon {
                                  margin-right: 5px;
                                  
                                  > i {
                                      font-size: 14px;
                                      color: ${colorMode === 'dark' ? '#fff' : '#000'};
                                  }
                              }
                          }

                          &-subtitle {
                            font-size: 85%;
                            opacity: .6;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                          }
                        }
                      }

                      &-right {
                        z-index: 1;
                        display: flex;
                        align-items: center;

                        &-icon {
                          margin-right: 5px;
                          opacity: ${colorMode === 'dark' ? 0.6 : 0.5};
                            display: flex;
                            align-items: center;
                            font-size: 85%;
                          i {
                              font-size: 14px;
                              color: ${colorMode === 'dark' ? '#fff' : '#000'};
                          }
                        }
                      }
                    }

                    &.is-darkmode {
                        color: rgb(212, 212, 212);
  
                        .is-cc {
                            color: #a7b4ff;
                        }
                    }

                    &.is-read {
                      .mail-item-header-left {
                        opacity: .4;
                      }
                      .mail-item-info-title {
                          font-weight: normal;
                      }
                    }

                    &.mobile-view {
                      .mail-item-header {
                        padding: 12px 15px 12px 9px;
                        height: 100%;
                      }
                    }

                    .mail-item-bottom {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-weight: 700;
                        line-height: 1.3rem;
                    }

                    .menu {
                      transition: width 100ms ease-out, opacity 100ms ease-out;
                      z-index: 1;

                      &.icon-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 25px;
                        height: 25px;

                        i {
                          font-size: 20px;
                        }
                      }

                      &.menu-grow {
                        &-enter {
                          width: 0;
                          opacity: 0;
                        }

                        &-enter-active {
                          width: 25px;
                          opacity: 1;
                        }

                        &-exit {
                          width: 25px;
                          opacity: 1;
                        }

                        &-exit-active {
                          width: 0;
                          opacity: 0;
                        }
                      }
                    }
                  }
                `}
            </style>
        </>
    ), [colorMode, highPrio, lowPrio, createdDateTime, dispatch, endpoint, from?.email, from?.id, from?.name, handleMouseEnter, handleMouseLeave, handleSelect, id, isCc, isHovered, isMobile, isRead, isSelected, isTablet, subject, tokenJti, width]);
};

MailItem.displayName = 'MailItem';

export default MailItem;
