import React, { useRef, useCallback, useEffect, useState } from 'react';
import Input from '@chayns-components/Input';
import { useDispatch, useSelector } from 'react-redux';

import { SearchInput } from '@chayns-components/core';
import {
    setIsSearchingMessages,
    setMessageSearchString,
    setSelectedItem,
} from '../../../../../../redux-modules/app/actions';
import { getTextStringState } from '../../../../../../redux-modules/text-strings/selector';
import { clearSearchMails, handleLoadSearchMails } from '../../../../../../redux-modules/smart-client/actions';
import { getAppState } from '../../../../../../redux-modules/app/selector';
import { getEnvState } from '../../../../../../redux-modules/env/selector';
import { getUserState } from '../../../../../../redux-modules/user/selector';
import TobitTeamByDavidLogo from '../../../../../util-components/TobitTeamByDavidLogo';

const InfoCenterHeader: React.FC = ({ infocenter }) => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        messageSearchString,
        colorMode,
    } = app;
    const user = useSelector(getUserState);

    const { isMyChaynsApp } = useSelector(getEnvState);

    const textStrings = useSelector(getTextStringState);

    const timeout = useRef(null);

    const [isSearchActive, setIsSearchActive] = useState(false);

    useEffect(() => {
        const loadMails = () => {
            if (!messageSearchString) {
                dispatch(setIsSearchingMessages(false));
                return;
            }

            if (messageSearchString.trim().length > 0) {
                dispatch(handleLoadSearchMails({
                    searchString: messageSearchString,
                }));
            } else {
                dispatch(setIsSearchingMessages(false));
            }
        };

        timeout.current = setTimeout(() => {
            loadMails();
        }, 500);
        return () => {
            clearTimeout(timeout.current);
        };
    }, [dispatch, messageSearchString]);

    const onChange = useCallback((value) => {
        dispatch(clearSearchMails());
        dispatch(setSelectedItem(null));
        dispatch(setMessageSearchString(value));
        if (value) {
            dispatch(setIsSearchingMessages(true));
        }
    }, [dispatch]);

    useEffect(() => {
        const searchIcon = document.querySelector('.input-group__icon');
        const handleIconClick = () => {
            const input: HTMLInputElement = document.querySelector('.message-input input');
            if (input) {
                input.focus();
            }
        };

        if (searchIcon) {
            searchIcon.addEventListener('click', handleIconClick);
        }

        return () => {
            if (searchIcon) {
                searchIcon.removeEventListener('click', handleIconClick);
            }
        };
    }, []);

    const color = colorMode !== 'dark' && !isMyChaynsApp ? 'black' : 'white';

    return (
        <>
            <div className="info-center-header">
                <div className="info-center-header__content">
                    {isMyChaynsApp && infocenter ? (
                        <div
                            className="info-center-header__content__app"
                        >
                            {!isSearchActive && (
                                <div className="info-center-header__content__app__logo">
                                    <TobitTeamByDavidLogo margin={0} height={40}/>
                                </div>
                            )}
                            <SearchInput
                                placeholder={textStrings?.['txt_chayns_de_find'] || 'Finden'}
                                onChange={(event) => onChange(event.target.value)}
                                value={messageSearchString}
                                isActive={isSearchActive}
                                onActiveChange={(active) => {
                                    setIsSearchActive(active);
                                    if (!active) {
                                        dispatch(setMessageSearchString(''));
                                        dispatch(setIsSearchingMessages(false));
                                    }
                                }}
                                iconColor="white"
                            />
                            {!isSearchActive && (
                                <div
                                    onClick={() => {
                                        chayns.selectTapp({
                                            id: chayns.env.isAndroid && (chayns.env.myChaynsAppVersion || chayns.env.appVersion) >= 6826 ? 552789 : -2,
                                        });
                                    }}
                                    className="info-center-header__content__app__user"
                                >
                                    <img
                                        src={`https://tsimg.cloud/${user.personId}/profile_w200-h200.png`}
                                        alt=""
                                        className="info-center-header__content__app__user__img"
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <Input
                            value={messageSearchString}
                            onChange={onChange}
                            icon="fal fa-search"
                            placeholder={textStrings?.['txt_chayns_de_find'] || 'Finden'}
                            className="message-input"
                        />
                    )}
                </div>
            </div>
            <style jsx global>
                {`
                    .info-center-header {
                        padding: ${isMyChaynsApp && infocenter ? '8px 15px 3px 15px' : '10px 16px 5px'};
                        min-height: ${isMyChaynsApp && infocenter ? 62 : 45}px;
                        display: flex;
                        align-items: center;
                        
                        &__content {
                            width: 100%;
                            
                            &__app {
                                display: flex;
                                justify-content: space-between;
                                
                                &__logo {
                                    flex-shrink: 0;
                                    display: flex;
                                    align-items: center;
                                }
                                
                                &__user {
                                    display: flex;
                                    flex-shrink: 0;
                                    align-items: center;
                                    color: white;
                                    cursor: pointer;
                                    
                                    &__img {
                                        border-radius: 50%;
                                        width: 32px;
                                        margin-left: 15px;
                                    }
                                }
                            }
                        }
                    }
                    .message-input {
                        border-bottom: none;
                        box-sizing: border-box;
                      
                      input {
                        color: ${color} !important;
                      }

                        .input-group__icon {
                            opacity: 1 !important; 
                            font-size: 14px;
                          color: ${color} !important;
                        }

                        ~ label {
                            font-size: 21px !important;
                            opacity: 1 !important;
                            color: ${color} !important;
                            top: unset !important;
                        }
                    }
                `}
            </style>
        </>
    );
};

InfoCenterHeader.displayName = 'InfoCenterHeader';

export default InfoCenterHeader;
